import Vue from 'vue'
import VueRouter from 'vue-router'
import front from '../views/nested/front.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'front',
        component: front,
        redirect: '/index',
        children: [{
            path: 'index2',
            component: () => import('../views/front/index.vue'),
            name: 'index2',
            meta: {
                title: '锐鹿保，专注于互联网保险科技服务',
                keywords:'锐鹿科技,通信电力行业雇主险,高空,非高空,办公室行政文员,一般制造业雇主险,机械加工雇主险,纺织雇主险,科技设计服务行业雇主险,线上扫码立即出单,操作方便',
                description:"锐鹿科技,雇主责任保险,雇主险,高空,高处作业,物流保险,装卸工,司机,叉车司机,员工,按月,短期保险,用工保险, 身故,伤残,误工,第三者责任,80万,100万,120万,保额,中国平安承保,电子保单,河北锐鹿网络科技有限公司"
            }
        }, {
            path: 'detail',
            component: () => import('../views/front/detail.vue'),
            name: 'detail',
            meta: {
                title: '锐鹿保，专注于互联网保险科技服务',
                keywords:'锐鹿保,雇主险,团体意外险,学平险,智能安全带,保险科技,互联网保险',
                description:"锐鹿保是一家科技赋能保险普惠平台，为保险公司提供产品研发、数据风控、精算定价及全国的互联网运营推广服务，减少成本投入60%以上，同时为全国多家企业、公共实体、协会以及专业服务组织提供风险管理、风险咨询等专业化服务。"
            }
        }, {
            path: 'employerInsurance',
            component: () => import('../views/front/employerInsurance.vue'),
            name: 'employerInsurance',
            meta: {
                title: '【锐鹿保电话：400-616-2199】',
                keywords:'雇主责任保险首选,按人,按职业灵活投保,支持随时增加,替换.锐鹿保联手中国平安打造全行业雇主责任险,低保费,高保额,保障全面,流程简单.【锐鹿保电话：400-616-2199】',
                description:"锐鹿科技,通信电力行业雇主险,高空,非高空,办公室行政文员,一般制造业雇主险,机械加工雇主险,纺织雇主险,科技设计服务行业雇主险,线上扫码立即出单,操作方便"
            }
        }, {
            path: 'index',
            component: () => import('../views/front/index2.vue'),
            name: 'index',
            meta: {
                title: '锐鹿保，专注于互联网保险科技服务',
                keywords:'锐鹿科技,通信电力行业雇主险,高空,非高空,办公室行政文员,一般制造业雇主险,机械加工雇主险,纺织雇主险,科技设计服务行业雇主险,线上扫码立即出单,操作方便',
                description:"锐鹿科技,雇主责任保险,雇主险,高空,高处作业,物流保险,叉车司机,员工,按月,短期保险,用工保险, 身故,伤残,误工,第三者责任,80万,100万,120万,保额,中国平安承保,电子保单,河北锐鹿网络科技有限公司"
            }
        }, {
            path: 'claimdata',
            component: () => import('../views/front/claimdata.vue'),
            name: 'claimdata',
            meta: {
                title: '锐鹿保，理赔知识库',
                keywords:'锐鹿科技,通信电力行业雇主险,高空,非高空,办公室行政文员,一般制造业雇主险,机械加工雇主险,纺织雇主险,科技设计服务行业雇主险,线上扫码立即出单,操作方便',
                description:"锐鹿科技,雇主责任保险,雇主险,高空,高处作业,物流保险,叉车司机,员工,按月,短期保险,用工保险, 身故,伤残,误工,第三者责任,80万,100万,120万,保额,中国平安承保,电子保单,河北锐鹿网络科技有限公司"
            }
        }]
    },
    {
        path: '/back',
        name: 'back',
        component: () => import('../views/nested/back.vue'),
        redirect: '/back/login',
        children: [{
            path: 'login',
            component: () => import('../views/back/login.vue'),
            name: 'login',
            meta: {
                title: '锐鹿保，专注于互联网保险科技服务',
                keywords:'雇主责任保险首选,按人,按职业灵活投保,支持随时增加,替换.锐鹿保联手中国平安打造全行业雇主责任险,低保费,高保额,保障全面,流程简单.关注锐鹿保公众号或小程序轻松投保和理赔【锐鹿保电话：400-616-2199】',
                description:"锐鹿保,锐鹿科技,官网,理赔信息查询,索赔清单及材料提交,理赔流程介绍,案件赔付时限,服务中心 保单查询,电子发票申领,电子保单验真,保单验证,保险条款查询,营业网点查询,支付帮助"
            }
        }, {
            path: 'guaranteeSlip',
            component: () => import('../views/back/guaranteeSlip.vue'),
            name: 'guaranteeSlip',
            meta: {
                title: '锐鹿保，专注于互联网保险科技服务',
                keywords:'雇主责任保险首选,按人,按职业灵活投保,支持随时增加,替换.锐鹿保联手中国平安打造全行业雇主责任险,低保费,高保额,保障全面,流程简单.关注锐鹿保公众号或小程序轻松投保和理赔【锐鹿保电话：400-616-2199】',
                description:"锐鹿保,锐鹿科技,官网,理赔信息查询,索赔清单及材料提交,理赔流程介绍,案件赔付时限,服务中心 保单查询,电子发票申领,电子保单验真,保单验证,保险条款查询,营业网点查询,支付帮助"
            }
        }, {
            path: 'correctOrder',
            component: () => import('../views/back/correctOrder.vue'),
            name: 'correctOrder',
            meta: {
                title: '锐鹿保，专注于互联网保险科技服务',
                keywords:'雇主责任保险首选,按人,按职业灵活投保,支持随时增加,替换.锐鹿保联手中国平安打造全行业雇主责任险,低保费,高保额,保障全面,流程简单.关注锐鹿保公众号或小程序轻松投保和理赔【锐鹿保电话：400-616-2199】',
                description:"雇主责任保险首选,按人,按职业灵活投保,支持随时增加,替换.锐鹿保联手中国平安打造全行业雇主责任险,低保费,高保额,保障全面,流程简单.关注锐鹿保公众号或小程序轻松投保和理赔【锐鹿保电话：400-616-2199】"
            }
        },{
            path: 'insure',
            component: () => import('../views/back/insure.vue'),
            name: 'insure',
            meta: {
                title: '锐鹿保，专注于互联网保险科技服务',
                keywords:'雇主责任保险首选,按人,按职业灵活投保,支持随时增加,替换.锐鹿保联手中国平安打造全行业雇主责任险,低保费,高保额,保障全面,流程简单.【锐鹿保电话：400-616-2199】',
                description:"锐鹿科技,雇主责任保险,雇主险,高空,高处作业,物流保险,叉车司机,员工,按月,短期保险,用工保险, 身故,伤残,误工,第三者责任,80万,100万,120万,保额,中国平安承保,电子保单,河北锐鹿网络科技有限公司"
            }
        },{
            path: 'guaranteeLook',
            component: () => import('../views/back/guaranteeLook.vue'),
            name: 'guaranteeLook',
            meta: {
                title: '锐鹿保，专注于互联网保险科技服务',
                keywords:'锐鹿保,雇主险,团体意外险,学平险,智能安全带,保险科技,互联网保险',
                description:"锐鹿保是一家科技赋能保险普惠平台，为保险公司提供产品研发、数据风控、精算定价及全国的互联网运营推广服务，减少成本投入60%以上，同时为全国多家企业、公共实体、协会以及专业服务组织提供风险管理、风险咨询等专业化服务。"
            }
        },{
            path: 'applyCorrect',
            component: () => import('../views/back/applyCorrect.vue'),
            name: 'applyCorrect',
            meta: {
                title: '锐鹿保，专注于互联网保险科技服务',
                keywords:'雇主责任保险首选,按人,按职业灵活投保,支持随时增加,替换.锐鹿保联手中国平安打造全行业雇主责任险,低保费,高保额,保障全面,流程简单.关注锐鹿保公众号或小程序轻松投保和理赔【锐鹿保电话：400-616-2199】',
                description:"锐鹿保,锐鹿科技,官网,理赔信息查询,索赔清单及材料提交,理赔流程介绍,案件赔付时限,服务中心 保单查询,电子发票申领,电子保单验真,保单验证,保险条款查询,营业网点查询,支付帮助"
            }
        },{
            path: 'correctLook',
            component: () => import('../views/back/correctLook.vue'),
            name: 'correctLook',
            meta: {
                title: '锐鹿保，专注于互联网保险科技服务',
                keywords:'雇主责任保险首选,按人,按职业灵活投保,支持随时增加,替换.锐鹿保联手中国平安打造全行业雇主责任险,低保费,高保额,保障全面,流程简单.关注锐鹿保公众号或小程序轻松投保和理赔【锐鹿保电话：400-616-2199】',
                description:"锐鹿保,锐鹿科技,官网,理赔信息查询,索赔清单及材料提交,理赔流程介绍,案件赔付时限,服务中心 保单查询,电子发票申领,电子保单验真,保单验证,保险条款查询,营业网点查询,支付帮助"
            }
        }]
    },{
        path: '/signet',
        name: 'signet',
        component: () => import('../views/nested/signet.vue'),
        redirect: '/signet/ident',
        children: [{
            path: 'ident',
            component: () => import('../views/signet/ident.vue'),
            name: 'ident',
            meta: {
                title: '锐鹿保---企业认证',
                keywords:'雇主责任保险首选,按人,按职业灵活投保,支持随时增加,替换.锐鹿保联手中国平安打造全行业雇主责任险,低保费,高保额,保障全面,流程简单.关注锐鹿保公众号或小程序轻松投保和理赔【锐鹿保电话：400-616-2199】',
                description:"锐鹿保,锐鹿科技,官网,理赔信息查询,索赔清单及材料提交,理赔流程介绍,案件赔付时限,服务中心 保单查询,电子发票申领,电子保单验真,保单验证,保险条款查询,营业网点查询,支付帮助"
            }
        },
        {
            path: 'apply',
            component: () => import('../views/signet/apply.vue'),
            name: 'apply',
            meta: {
                title: '锐鹿保---电子签申请',
                keywords:'雇主责任保险首选,按人,按职业灵活投保,支持随时增加,替换.锐鹿保联手中国平安打造全行业雇主责任险,低保费,高保额,保障全面,流程简单.关注锐鹿保公众号或小程序轻松投保和理赔【锐鹿保电话：400-616-2199】',
                description:"锐鹿保,锐鹿科技,官网,理赔信息查询,索赔清单及材料提交,理赔流程介绍,案件赔付时限,服务中心 保单查询,电子发票申领,电子保单验真,保单验证,保险条款查询,营业网点查询,支付帮助"
            }
        },
        {
            path: 'create',
            component: () => import('../views/signet/create.vue'),
            name: 'create',
            meta: {
                title: '锐鹿保---生成电子签',
                keywords:'雇主责任保险首选,按人,按职业灵活投保,支持随时增加,替换.锐鹿保联手中国平安打造全行业雇主责任险,低保费,高保额,保障全面,流程简单.关注锐鹿保公众号或小程序轻松投保和理赔【锐鹿保电话：400-616-2199】',
                description:"锐鹿保,锐鹿科技,官网,理赔信息查询,索赔清单及材料提交,理赔流程介绍,案件赔付时限,服务中心 保单查询,电子发票申领,电子保单验真,保单验证,保险条款查询,营业网点查询,支付帮助"
            }
        },{
            path: 'audit',
            component: () => import('../views/signet/audit.vue'),
            name: 'audit',
            meta: {
                title: '锐鹿保---电子签查看',
                keywords:'雇主责任保险首选,按人,按职业灵活投保,支持随时增加,替换.锐鹿保联手中国平安打造全行业雇主责任险,低保费,高保额,保障全面,流程简单.关注锐鹿保公众号或小程序轻松投保和理赔【锐鹿保电话：400-616-2199】',
                description:"锐鹿保,锐鹿科技,官网,理赔信息查询,索赔清单及材料提交,理赔流程介绍,案件赔付时限,服务中心 保单查询,电子发票申领,电子保单验真,保单验证,保险条款查询,营业网点查询,支付帮助"
            }
        }]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
